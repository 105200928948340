@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
.header-app {   
    position: fixed;
    top: 0;
    width: 100%;    
    background-color: rgba(0, 0, 0, 0.9);  
    z-index: 100;
    height: 9vh;
    padding: 0.5vh;
}

.header-app label {
    float: right;
}

.logo{        
    height: 3em;
    width: 3em;
}

.login{    
    cursor: pointer;
    height: 3em;
    width: 3em;    
    float: right;
    border-radius: 50%;
}

.user-menu{
    color: black;
    font-weight: bolder;
    border-radius: 1%; 
    text-align: center; 
    padding: 2%; 
    display: none;
    background-color: white; 
    right: 0; 
    top: 2.5em; 
    position: absolute;
}

#notificacao-menu{
    overflow-y: scroll;
    color: black;
    font-weight: bolder;
    border-radius: 1%; 
    text-align: center; 
    padding: 2%; 
    background-color: white; 
    top: 1.8em; 
    right:4rem; 
    position: absolute;
    padding: 0;
    width: 0;
    height: 0;
    transition: all 0.3s ease-in-out;
}

.notificacao-botao{    
    cursor:pointer;
    display: inline-block;
}

.notificacao-botao div{
    top:25%; 
    right:4rem; 
    position:absolute; 
    box-sizing:border-box;
    font-size:7pt;
    height:1.1rem;
    width:1.1rem;
    background-color:red;
    border-radius:50%;
    text-align:center;
}

#notificacao-check{
    float: right;
    display: none;
}

#notificacao-check:checked  ~ #notificacao-menu{
    width: 50%;
    height: 80vh;
}

.menu{
    position: fixed; 
    padding-top: 3.5em;
    z-index: 100;
    height: 100%;
}

.check-menu{
    display: none;
}

.botao-menu{    
    cursor: pointer;
    font-weight: bolder; 
    font-size: 30pt;
    position: fixed;
}

.menu-conteudo{      
    background-color: rgba(0, 0, 0, 0.9);
    width: 0;    
    height: 100%;    
    transition: all 0.9s ease-in-out; 
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
    padding-top: 2.5em;
}
 
.check-menu:checked ~ .menu-conteudo{
    width: 100%;
}       

.menu-legenda{
    margin-left: 2vh;    
    text-overflow: ellipsis;
}

.menu-conteudo ul{    
    list-style:none;    
    font-weight: bolder;
    padding-left: 1em;
}

.menu-conteudo li{
    color: white;
    white-space: nowrap;
}

.menu-conteudo li{
    text-decoration: none;
    padding: 0.5em 0.5em 0.5em 0.5em;
    transition: all 0.9s ease-in-out;
}

.menu-conteudo li:hover{
    text-decoration: underline;
    background-color: white;
    background-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(122,115,115,1) 100%);
    background-size: auto 2px;
    color: rgba(53, 49, 99, 0.959);;
}

.menu-conteudo a{
    color: white;
}




#conteudo{      
    width: 100%;  
    padding-top: 11vh;   
    border-radius: 1%;
    height: 100%;   
    overflow: hidden;
    box-sizing: border-box;
}


.background{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.conteudo-login{
    background-color: white;    
    color: #000;
    padding: 1%;
    border-radius: 5px;
    box-shadow: 3px 3px 8px #ccc;
    position: relative;
    text-align: center;
    left: 25%;
    top: 20%;
    width: 50%;
    padding: 2% 0;
}    


.card-contas{
    border-radius: 0.5%; 
    margin-bottom: 1%;   
    padding: 2em;
    background-color: white;
    color: #000;
}   

.header-card{
    white-space: nowrap;    
    overflow-x: scroll;
}





.card-anuncios{
    margin: 0.9%;
    box-shadow: 5px 5px 10px black;
    border-radius: 5px;
    width: 100%; 
    display: flex;  
}

.anuncio-info{
    width: 100%;
    height: 100%;
    padding: 2% 10% 1% 5%;
}

.anuncio-info h3{
    text-align: center;
    font-weight: bold;
    font-size: 12pt;
    border-bottom: 1px solid rgba(0, 0 , 0, 0.1);
}

.foto-lista-anuncio{
    width: 5em;
    height: 5em;
    border-radius: 5px;
    float: left;
    display: inline;
}


.anuncio-especificacao{
    margin-top: 2%;
    max-width: 100%;
}

.anuncio-especificacao label{
    margin-right: 5%;
    display: inline-flex;  
}

.seta-baixo-atributos-variacoes, .seta-baixo-imagens-variacao, .conteudo-atributos-variacoes-form,
.conteudo-imagens-variacao-form, #check-imagens-variacao-fieldset, 
#check-atributos-variacoes-fieldset,
#check-atributos-variacoes-fieldset:checked ~ .seta-direita-atributos-variacoes,
#check-imagens-variacao-fieldset:checked ~ .seta-direita-imagens-variacao{
    display: none;
}

#check-imagens-variacao-fieldset:checked ~ .seta-baixo-imagens-variacao,
#check-imagens-variacao-fieldset:checked ~ .conteudo-imagens-variacao-form,
#check-atributos-variacoes-fieldset:checked ~ .seta-baixo-atributos-variacoes,
#check-atributos-variacoes-fieldset:checked ~ .conteudo-atributos-variacoes-form{    
    display: unset;
}

.botao-add-atributo{
    background-color: rgb(39, 161, 15);
    color: white;    
    border-radius: 0.5em;    
    font-size: 10pt !important;	
}

.botao-excluir-atributo{
    background-color: rgb(190, 43, 38);
    color: white;    
    border-radius: 0.5em;    
    font-size: 8pt !important;	
}

.botao-editar-atributo{
    background-color: rgb(25, 116, 190);
    color: white;    
    border-radius: 0.5em;    
    font-size: 8pt !important;	
}



.form-add-variacao-rodape{    
    margin-top: 1%;    
    padding-left: 50%; 
}

.botao-add-variacao{    
    background-color: rgb(15, 78, 161);
    color: white;    
    border-radius: 0.5em;    
    font-size: 8pt !important;	           
}

.botao-excluir-variacao{
    background-color: rgba(226, 7, 7, 0.3);
    color: white;    
    border-radius: 0.5em;   
    font-size: 8pt !important;	 
}




.botao-publicar{
    background-color: rgb(6, 108, 241);
    color: white;    
    border-radius: 0.5em;    
    font-size: 15pt;
	margin-left: 85%;
}

/* .background{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9)  
} */
/* .conteudo-login{
    position: static;
    background-color: white;
    padding: 1%;
    margin: auto;
    margin-top: 10em;
    width: 15em;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0px 0px 1000px #ccc;
} */

    

.menu-feed ul{
    list-style: none; 
    text-align: center;  
    margin: 0;          
}
.menu-feed{    
    height: 2em;
    box-sizing: border-box;
    border: black;
    position: relative;
    white-space: nowrap;
    background-image: linear-gradient(90deg, rgba(89,86,86,1) 0%, rgba(41,39,39,1) 100%);
    box-shadow: 1px 1px 3px 0 rgba(89, 86, 86, 0.877);
}
.menu-feed{    
    height: 2em;
    box-sizing: border-box;
    border: black;
    position: relative;
    white-space: nowrap;
    background-image: linear-gradient(90deg, rgba(89,86,86,1) 0%, rgba(41,39,39,1) 100%);
    box-shadow: 1px 1px 3px 0 rgba(89, 86, 86, 0.877);
}
.perfil-menu, .perfil-menu-opaciti{
    color: white;
    border: none;
    transition: opacity 0.8s linear;
    display: inline-block;    
    cursor: pointer;
    font-weight: bolder;
    background-color: transparent;
}
.perfil-menu{
    opacity: 0; 
    z-index: 2; 
    position: relative;
    width: 100%;
}
.perfil-menu-opaciti{
    z-index: 1;
    position: absolute;
    width: 33.33%;
}
.perfil-menu:hover{ 
    opacity: 1;
}
.perfil-menu:active{
    border: 1px solid rgb(247, 247, 247);
}
.tabs-feed{
    height: 92%;
    margin-top: 1%;    
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  
    scrollbar-width: none;  
}

.tab{
    display: inline-block; 
    width: 100vw; 
    height: 100%; 
    vertical-align:top; 
}


.anuncios-conteudo{
    height: 100%; 
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    box-sizing: border-box;
}

.card-anuncio{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    border-radius: 1em;
    margin-bottom: 4%;
    color: black;
    background-color: white;
}

.img-anuncio{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
    display: inline-block;
}

.teste{
overflow-x: scroll;
}

@media only screen and (min-width: 600px) {
    .anuncios-conteudo{
        padding: 0 30%;
    }
}
.lojas-conteudo{
    height: 100%; 
    overflow-y: scroll;
    box-sizing: border-box;
}

.card-loja{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    box-sizing: border-box;
    border-radius: 1em;
    margin-bottom: 4%;
    width: 100%;
    color: black;
    background-color: white;
}

.img-loja{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
}

@media only screen and (min-width: 600px) {
    .lojas-conteudo{
        padding: 0 30%;
    }
}
.produtos-conteudo{
    width: 100vw;
    overflow-y: scroll;
    height: 100%;  
    box-sizing: border-box;
}

.card-produto{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    border-radius: 1em;
    margin-bottom: 4%;
    color: black;
    background-color: white;
}

.img-produto{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
}
.teste{
    overflow-x: scroll;
    display: inline-flex;
    width:100%;
    height:100%;
}

@media only screen and (min-width: 600px) {
    .produtos-conteudo{
        padding: 0 30%;
    }
}

#detalhar-perfil-header{
    height:25%;
    width: 100%;
    overflow: hidden;
}
#detalhar-perfil-header img{
    border-radius: 50%; 
    width: 4em;
    height: 4em;
}
#detalhar-perfil-header h1, #detalhar-perfil-header h2{
    font-size: 12pt;
}
#detalhar-perfil-header h1{
    width: 40%;
}
#detalhar-perfil-header h1, .seguidores, #detalhar-perfil-header h2{
    display: inline-block;
}
.seguidores{
    vertical-align: top;
    text-align: center;
    width: 60%;
}
.seguidores button{
    cursor: pointer;
    width: 7.5em;
}
.menu-feed{    
    height: 2rem;
    box-sizing: border-box;
    border: black;
    position: relative;
    white-space: nowrap;
    background-image: linear-gradient(90deg, rgba(89,86,86,1) 0%, rgba(41,39,39,1) 100%);
    box-shadow: 1px 1px 5px 0 rgba(89, 86, 86, 0.877);
}
.perfil-menu, .perfil-menu-opaciti{
    color: white;
    border: none;
    transition: opacity 0.8s linear;
    display: inline-block;    
    cursor: pointer;
    font-weight: bolder;
    background-color: transparent;
    
}
.perfil-menu{
    opacity: 0; 
    background-image: linear-gradient(90deg, rgb(182, 177, 177) 0%, rgb(133, 128, 128) 100%);
    z-index: 2; 
    position: relative;
}
.perfil-menu-opaciti{
    z-index: 1;
    position: absolute;
}
.perfil-menu:hover{ 
    opacity: 1;
}
.perfil-menu:active{
    border: 1px solid rgb(247, 247, 247);
    box-sizing: border-box;
}
#tabs{
    padding-top: 0.5%;
    height: 69%; 
    overflow-x: scroll;
    white-space: nowrap;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  
    scrollbar-width: none; 
}

.tab{
    background-color: black; 
    display: inline-block; 
    width: 100vw; 
    height: 100%; 
    vertical-align:top;    
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
 form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	/* vertical-align: baseline; */
	font-family: Arial, Helvetica, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 2;
	/* overflow: hidden !important; */
	color: white !important;	
	background-color: rgb(0, 0, 0) !important;	
}
ol, ul {
	list-style: none;
	padding: 0 !important;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
::-webkit-scrollbar {
    width: 9px;
	height: 9px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background: #f1f1f1;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background: #f1f1f1;
}

a{
	text-decoration: inherit !important;
	color: inherit;
}
input[type=submit],input[type=file],input[type=button]{
	cursor: pointer;
}
input{
	font-family: 'Architects Daughter', cursive;
	padding: 0.2% 0.8% 0.2% 0.8%;	
	border: none;	
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;		
	color: #000;		
}

input::placeholder{
    font-size: 11pt; 
    transition: 0.4s;   
}

input:focus::placeholder{
    font-size: 7pt;    
}

input:focus{
	outline: none;
}

select{
	font-family: 'Architects Daughter', cursive;
	padding: 0.2% 0.8% 0.2% 0.8%;	
	border-radius: 0.3em;	
	border: 1px solid #ccc;
	box-sizing: border-box;
}

.table > :not(caption) > * > *{
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

fieldset legend{
    float: none;
}

fieldset {
    min-width: auto !important;
}

table{
	border-collapse: "collapse";
	width: "100%";
}

tr:hover {
	background-color: white;
	color: #000;
}
body, html{
    height: 100% !important;
}
