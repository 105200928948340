.lojas-conteudo{
    height: 100%; 
    overflow-y: scroll;
    box-sizing: border-box;
}

.card-loja{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    box-sizing: border-box;
    border-radius: 1em;
    margin-bottom: 4%;
    width: 100%;
    color: black;
    background-color: white;
}

.img-loja{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
}

@media only screen and (min-width: 600px) {
    .lojas-conteudo{
        padding: 0 30%;
    }
}