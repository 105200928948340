.header-app {   
    position: fixed;
    top: 0;
    width: 100%;    
    background-color: rgba(0, 0, 0, 0.9);  
    z-index: 100;
    height: 9vh;
    padding: 0.5vh;
}

.header-app label {
    float: right;
}

.logo{        
    height: 3em;
    width: 3em;
}

.login{    
    cursor: pointer;
    height: 3em;
    width: 3em;    
    float: right;
    border-radius: 50%;
}

.user-menu{
    color: black;
    font-weight: bolder;
    border-radius: 1%; 
    text-align: center; 
    padding: 2%; 
    display: none;
    background-color: white; 
    right: 0; 
    top: 2.5em; 
    position: absolute;
}

#notificacao-menu{
    overflow-y: scroll;
    color: black;
    font-weight: bolder;
    border-radius: 1%; 
    text-align: center; 
    padding: 2%; 
    background-color: white; 
    top: 1.8em; 
    right:4rem; 
    position: absolute;
    padding: 0;
    width: 0;
    height: 0;
    transition: all 0.3s ease-in-out;
}

.notificacao-botao{    
    cursor:pointer;
    display: inline-block;
}

.notificacao-botao div{
    top:25%; 
    right:4rem; 
    position:absolute; 
    box-sizing:border-box;
    font-size:7pt;
    height:1.1rem;
    width:1.1rem;
    background-color:red;
    border-radius:50%;
    text-align:center;
}

#notificacao-check{
    float: right;
    display: none;
}

#notificacao-check:checked  ~ #notificacao-menu{
    width: 50%;
    height: 80vh;
}
