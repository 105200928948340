.background{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
}

.conteudo-login{
    background-color: white;    
    color: #000;
    padding: 1%;
    border-radius: 5px;
    box-shadow: 3px 3px 8px #ccc;
    position: relative;
    text-align: center;
    left: 25%;
    top: 20%;
    width: 50%;
    padding: 2% 0;
}    