
.card-anuncios{
    margin: 0.9%;
    box-shadow: 5px 5px 10px black;
    border-radius: 5px;
    width: 100%; 
    display: flex;  
}

.anuncio-info{
    width: 100%;
    height: 100%;
    padding: 2% 10% 1% 5%;
}

.anuncio-info h3{
    text-align: center;
    font-weight: bold;
    font-size: 12pt;
    border-bottom: 1px solid rgba(0, 0 , 0, 0.1);
}

.foto-lista-anuncio{
    width: 5em;
    height: 5em;
    border-radius: 5px;
    float: left;
    display: inline;
}


.anuncio-especificacao{
    margin-top: 2%;
    max-width: 100%;
}

.anuncio-especificacao label{
    margin-right: 5%;
    display: inline-flex;  
}