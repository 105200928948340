.produtos-conteudo{
    width: 100vw;
    overflow-y: scroll;
    height: 100%;  
    box-sizing: border-box;
}

.card-produto{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    border-radius: 1em;
    margin-bottom: 4%;
    color: black;
    background-color: white;
}

.img-produto{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
}
.teste{
    overflow-x: scroll;
    display: inline-flex;
    width:100%;
    height:100%;
}

@media only screen and (min-width: 600px) {
    .produtos-conteudo{
        padding: 0 30%;
    }
}