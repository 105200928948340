@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap');

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
 form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	/* vertical-align: baseline; */
	font-family: Arial, Helvetica, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 2;
	/* overflow: hidden !important; */
	color: white !important;	
	background-color: rgb(0, 0, 0) !important;	
}
ol, ul {
	list-style: none;
	padding: 0 !important;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
::-webkit-scrollbar {
    width: 9px;
	height: 9px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
    background: #f1f1f1;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    background: #f1f1f1;
}

a{
	text-decoration: inherit !important;
	color: inherit;
}
input[type=submit],input[type=file],input[type=button]{
	cursor: pointer;
}
input{
	font-family: 'Architects Daughter', cursive;
	padding: 0.2% 0.8% 0.2% 0.8%;	
	border: none;	
	border-bottom: 1px solid #ccc;
	box-sizing: border-box;		
	color: #000;		
}

input::placeholder{
    font-size: 11pt; 
    transition: 0.4s;   
}

input:focus::placeholder{
    font-size: 7pt;    
}

input:focus{
	outline: none;
}

select{
	font-family: 'Architects Daughter', cursive;
	padding: 0.2% 0.8% 0.2% 0.8%;	
	border-radius: 0.3em;	
	border: 1px solid #ccc;
	box-sizing: border-box;
}

.table > :not(caption) > * > *{
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

fieldset legend{
    float: none;
}

fieldset {
    min-width: auto !important;
}

table{
	border-collapse: "collapse";
	width: "100%";
}

tr:hover {
	background-color: white;
	color: #000;
}
body, html{
    height: 100% !important;
}