.menu{
    position: fixed; 
    padding-top: 3.5em;
    z-index: 100;
    height: 100%;
}

.check-menu{
    display: none;
}

.botao-menu{    
    cursor: pointer;
    font-weight: bolder; 
    font-size: 30pt;
    position: fixed;
}

.menu-conteudo{      
    background-color: rgba(0, 0, 0, 0.9);
    width: 0;    
    height: 100%;    
    transition: all 0.9s ease-in-out; 
    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 1;
    padding-top: 2.5em;
}
 
.check-menu:checked ~ .menu-conteudo{
    width: 100%;
}       

.menu-legenda{
    margin-left: 2vh;    
    text-overflow: ellipsis;
}

.menu-conteudo ul{    
    list-style:none;    
    font-weight: bolder;
    padding-left: 1em;
}

.menu-conteudo li{
    color: white;
    white-space: nowrap;
}

.menu-conteudo li{
    text-decoration: none;
    padding: 0.5em 0.5em 0.5em 0.5em;       
    -webkit-transition: all 0.9s ease-in-out;
    transition: all 0.9s ease-in-out;
}

.menu-conteudo li:hover{
    text-decoration: underline;
    background-color: white;
    background-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(122,115,115,1) 100%);
    background-size: auto 2px;
    color: rgba(53, 49, 99, 0.959);;
}

.menu-conteudo a{
    color: white;
}

