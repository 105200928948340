.anuncios-conteudo{
    height: 100%; 
    width: 100%;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    box-sizing: border-box;
}

.card-anuncio{
    border: 1px solid rgba(0, 0 , 0, 0.1);
    border-radius: 1em;
    margin-bottom: 4%;
    color: black;
    background-color: white;
}

.img-anuncio{
    min-width: 100%; 
    max-width: 100%; 
    max-height: 16em;
    display: inline-block;
}

.teste{
overflow-x: scroll;
}

@media only screen and (min-width: 600px) {
    .anuncios-conteudo{
        padding: 0 30%;
    }
}