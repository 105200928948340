

.card-contas{
    border-radius: 0.5%; 
    margin-bottom: 1%;   
    padding: 2em;
    background-color: white;
    color: #000;
}   

.header-card{
    white-space: nowrap;    
    overflow-x: scroll;
}



